import Message from './constants'

const CryptoJS = require('crypto-js')
/* eslint-disable */
function convertUint8ArrayToWordArray(u8Array) {
  const words = []; let i = 0; const
    len = u8Array.length

  while (i < len) {
    words.push(
      (u8Array[i++] << 24)
        | (u8Array[i++] << 16)
        | (u8Array[i++] << 8)
        | (u8Array[i++]),
    )
  }

  return {
    sigBytes: words.length * 4,
    words,
  }
}
const key = '26f140529a54e1050624ad0bdbe6626c'

export default function (data,url) {
  const msg = Message[url].create(data)
  const message = Message[url].encode(msg).finish()
  return CryptoJS.AES.encrypt(convertUint8ArrayToWordArray(message), key).toString()
}
