import encoder from '@core/protobuf/encoder'
import decoder from '@core/protobuf/decoder'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()
        // eslint-disable-next-line no-param-reassign
        config.data = encoder(config.data, config.url)
        // eslint-disable-next-line no-param-reassign
        config.baseURL = 'http://ofis.makrosum.com:61545'
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${store.state.userInfo.tokenPrefix} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        /* eslint-disable no-param-reassign */
        if (response.config.url === '/api/auth/login/') {
          localStorage.setItem('userData', response.data)
        }
        response.data = decoder(response.data, response.config.url)
        response.data = response.data.sonuc !== undefined ? response.data.sonuc : response.data
        return response
      },
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              const dd = decoder(r.data)
              this.setToken(dd.access)
              this.setRefreshToken(dd.refresh)

              this.onAccessTokenFetched(dd.access)
              // eslint-disable-next-line no-unused-vars
            })
          } else {
            localStorage.clear()
            router.push({ name: 'giris' })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              originalRequest.headers['Content-Type'] = 'application/json'
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  // Logins
  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken(),
    })
  }

  // Kantinim
  kartGetir(...args) {
    return this.axiosIns.post(this.jwtConfig.ogrenciSorgular, ...args)
  }

  stokGetir(...args) {
    return this.axiosIns.post(this.jwtConfig.stokSorgular, ...args)
  }

  kullaniciGuncelle(...args) {
    return this.axiosIns.post(this.jwtConfig.kullaniciSorgular, ...args)
  }

  ogrenci(...args) {
    return this.axiosIns.post(this.jwtConfig.ogrenciSorgular, ...args)
  }

  sepet(...args) {
    return this.axiosIns.post(this.jwtConfig.sepetSorgular, ...args)
  }

  grup(...args) {
    return this.axiosIns.post(this.jwtConfig.grupSorgular, ...args)
  }

  pdksgrup(...args) {
    return this.axiosIns.post(this.jwtConfig.pdksGrupSorgular, ...args)
  }
}
