<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <b-dropdown
        v-if="$route.name !== 'anasayfa'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :text="sayfalar[$route.name]"
        variant="flat-primary"
        class="d-lg-block"
      >
        <b-dropdown-item
          target="popup"
          onclick="window.open('hizlisatis', 'popup', `width=${screen.availWidth},height=${screen.availHeight},directories=no,menubar=no,toolbars=no,fullscreen=yes`)"
        >
          Hızlı Satış
        </b-dropdown-item>
        <b-dropdown-item
          v-for="adi,degeri in sayfalar"
          v-if="$route.name !== degeri && degeri !== 'hizlisatis'"
          :key="degeri"
          @click="$router.push({name:degeri})"
        >
          {{ adi }}
        </b-dropdown-item>

      </b-dropdown>
      <b-dropdown
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        text="Dönem"
        variant="flat-primary"
        class="d-lg-block"
      >
        <b-dropdown-item>
          2021
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              John Doe
            </p>
            <span class="user-status">Admin</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="cikisYap"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Çıkış</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BDropdown,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BDropdown,
    // Navbar Components
    DarkToggler,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      sayfalar: {
        hizlisatis: 'Hızlı Satış',
        tanimlamalar: 'Tanımlamalar',
        hareketler: 'Hareketler',
        yemekhane: 'Yemekhane',
        operasyonlar: 'Operasyonlar',
        'yoklama-sistemi': 'Yoklama Sistemi',
        store: 'Store',
        kutuphane: 'Kütüphane',
        'veli-paneli': 'Veli Paneli',
      },
    }
  },
  methods: {
    cikisYap() {

    },
  },
}
</script>
