export default {
  // Endpoints
  loginEndpoint: '/api/auth/login/',
  registerEndpoint: '/api/auth/login/',
  refreshEndpoint: '/api/auth/refresht/',
  logoutEndpoint: '/api/auth/logout/',
  // Kantinim
  ogrenciSorgular: '/api/ogrenci/',
  stokSorgular: '/api/stok/',
  kullaniciSorgular: '/api/kullanici/',
  sepetSorgular: '/api/sepet/',
  grupSorgular: '/api/grup/',
  pdksGrupSorgular: '/api/pdksgrup/',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
